<template>
  <v-layout class="rounded rounded-md">
    <v-navigation-drawer class="bg-leftarea text-white">
      <div class="mt-3">
        <ImageLogo />
      </div>
      <Menu v-if="page?.menu" :menu="page.menu" :nav="true" bg-color="leftarea" color="white" class="pt-4" />
    </v-navigation-drawer>

    <v-app-bar class="bg-appbar">
      <v-row no-gutters>
        <v-icon
          icon="$menu"
          class="v-col-2 d-lg-none d-flex justify-center"
          color="#FFFFFF"
          size="large"
          aria-label="menu"
          @click.stop="drawer = !drawer"
        ></v-icon>

        <v-col cols="6" class="d-flex justify-center d-lg-none">
          <ImageLogo />
        </v-col>

        <v-col cols="4" lg="12" sm="4">
          <Button
            :download-nick="nick"
            :software-title="soft"
            :text="page.downloadTitle"
            class="bg-blueButton text-white mr-2 pa-2 float-right"
          />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary location="left" class="bg-leftarea">
      <Menu v-if="page?.menu" :menu="page.menu" nav class="mt-4" />
    </v-navigation-drawer>
    <v-main class="align-center justify-center bg-main mt-10" style="min-height: 300px">
     <breadcrumbs  v-if="page.breadcrumbs && page.breadcrumbs.length > 0"
        :items="page.breadcrumbs"
        deviderIcon="$chevronRight"
        deviderColor="breadcrumbs"
         class="py-0 mt-4 ma-0" />
      <aside v-if="page?.blocks?.AboveContent" class="mx-1">
        <Region :key="page.uri" region="AboveContent" :blocks="page.blocks.AboveContent" />
      </aside>

      <v-row v-if="page?.blocks?.RightSide" no-gutters>
        <v-col cols="12" md="9">
          <article class="rounded ma-2 pa-5">
            <Article :key="page.uri" class="ma-2 pa-3"></Article>

            <Button
              :download-nick="nick"
              :software-title="soft"
              :text="page.downloadTitle"
              class="d-block ma-auto pa-2 mt-2 text-uppercase font-weight-bold"
            />
          </article>
          <aside v-if="page?.blocks?.BelowContent">
            <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
          </aside>
        </v-col>
        <v-col cols="12" md="3">
          <aside v-if="page?.blocks?.RightSide" class="ml-2 mr-2">
            <Region
              :key="page.uri"
              region="RightSide"
              :blocks="page.blocks.RightSide"
              class="blockDesign rightBlock"
              @noregion="page.hideRegion"
            />
          </aside>
        </v-col>
      </v-row>
      <template v-else>
        <article class="rounded ma-2 pa-5">
          <Article :key="page.uri" class="ma-2 pa-3"></Article>

          <Button
            :download-nick="nick"
            :software-title="soft"
            :text="page.downloadTitle"
            class="d-block ma-auto pa-2 mt-2 text-uppercase font-weight-bold"
          />
        </article>

        <aside v-if="page?.blocks?.BelowContent">
          <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
        </aside>
      </template>

      <footer v-if="page?.blocks?.Footer" class="mt-5 pt-1">
        <Region :key="page.uri" region="Footer" :blocks="page.blocks.Footer" />
      </footer>
    </v-main>
  </v-layout>
</template>
<script setup>
const page = inject("page");
const nick = ref(unref(page)?.rels?.downloadNick);
const soft = ref(unref(page)?.rels?.softwareTitle);
const drawer = ref(false);

watchEffect(() => {
  nick.value = unref(page)?.rels?.downloadNick;
  soft.value = unref(page)?.rels?.softwareTitle;
});
</script>
